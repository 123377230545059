<div [formGroup]="formGroup" class="email-entry">

    <div class="email-entry__form-controls">
        <mat-form-field class="half-width" floatLabel="always" data-test="emailSubject">
            <mat-label>{{ EmailEntryFormLabelsEnum.SUBJECT }}</mat-label>
            <input matInput autocomplete="off" [formControlName]="EmailEntryFormNamesEnum.SUBJECT" required />
            <mat-hint align="end">{{ formGroup.get(EmailEntryFormNamesEnum.SUBJECT)?.value?.length || 0 }} /
                {{ EmailEntryMaxLengthsEnum.SUBJECT }}</mat-hint>
            <mat-error *ngIf="formGroup.get(EmailEntryFormNamesEnum.SUBJECT).hasError('required')">
                Required
            </mat-error>
            <mat-error *ngIf="formGroup.get(EmailEntryFormNamesEnum.SUBJECT).hasError('pattern')">
                Invalid format! Valid is "DSP###### | Subject"
            </mat-error>
        </mat-form-field>
        <mat-form-field class="half-width" floatLabel="always" *ngIf="!config?.hideRecipient" data-test="emailRecipients">
            <mat-label>{{ EmailEntryFormLabelsEnum.RECIPIENTS }}</mat-label>
            <input matInput autocomplete="off" [formControlName]="EmailEntryFormNamesEnum.RECIPIENTS" required #recipients (input)="recipients.value=replaceWhiteSpacesWithComa($event.target.value)" />
            <mat-error *ngIf="formGroup.get(EmailEntryFormNamesEnum.RECIPIENTS).errors">
                <span *ngIf="formGroup.get(EmailEntryFormNamesEnum.RECIPIENTS).hasError('required')">Required</span>
                <span *ngIf="formGroup.get(EmailEntryFormNamesEnum.RECIPIENTS).hasError('email')">Invalid</span>
            </mat-error>
        </mat-form-field>
    </div>

    <div class="email-entry__form-controls row-section2">
        <mat-form-field class="half-width" floatLabel="always" data-test="emailCC">
            <mat-label>{{ EmailEntryFormLabelsEnum.CC }}</mat-label>
            <input matInput autocomplete="off" [formControlName]="EmailEntryFormNamesEnum.CC" #cc (input)="cc.value=replaceWhiteSpacesWithComa($event.target.value)" />
            <mat-error *ngIf="formGroup.get(EmailEntryFormNamesEnum.CC).hasError('email')">
                Invalid
            </mat-error>
        </mat-form-field>
        <mat-form-field class="half-width" floatLabel="always" *ngIf="!config?.hideTemplate" data-test="emailTemplate">
            <mat-label>{{ EmailEntryFormLabelsEnum.TEMPLATE }}</mat-label>
            <mat-select #template [formControlName]="EmailEntryFormNamesEnum.TEMPLATE">
                <mat-option *ngFor="let template of templates" [value]="template">{{ template.typeCd | asWords }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="email-entry__editor">
        <angular-editor [config]="editorConfig" [formControlName]="EmailEntryFormNamesEnum.EMAIL_CONTENT" data-test="emailEditor"></angular-editor>
        <mat-error>
            <div *ngIf="formGroup.get(EmailEntryFormNamesEnum.EMAIL_CONTENT).hasError('required')">
                Required
            </div>
        </mat-error>
    </div>
</div>
