import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { xpoEmailValidator } from '../../validators';
import { FormatValidationService } from '@xpo-ltl/common-services';

import { EmailEntryFormNamesEnum, EmailEntryMaxLengthsEnum } from '../../enums';

export class EmailEntryFormBuilder {
  static create(fb: FormBuilder, formatValidationService: FormatValidationService): FormGroup {
    return fb.group({
      [EmailEntryFormNamesEnum.SUBJECT]: [
        '',
        [Validators.required, Validators.maxLength(EmailEntryMaxLengthsEnum.SUBJECT), Validators.pattern(/^DSP\d{7} \|.*/)]
      ],
      [EmailEntryFormNamesEnum.RECIPIENTS]: [
        '',
        [
          Validators.required,
          Validators.maxLength(EmailEntryMaxLengthsEnum.RECIPIENTS),
          xpoEmailValidator(formatValidationService),
        ],
      ],
      [EmailEntryFormNamesEnum.CC]: [
        '',
        [Validators.maxLength(EmailEntryMaxLengthsEnum.CC), xpoEmailValidator(formatValidationService)],
      ],
      [EmailEntryFormNamesEnum.TEMPLATE]: [''],
      [EmailEntryFormNamesEnum.EMAIL_CONTENT]: ['', [Validators.required]],
    });
  }
}
